import { UIState, uiState } from "@/contexts/uiState";
import BrokenImageIcon from "@mui/icons-material/BrokenImage";
import { Badge, Box, Button, Chip, Skeleton, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import Slider, { Settings } from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Bugsnag from "@bugsnag/js";

class ImageLoadError extends Error {
  constructor(message) {
    super(message);
    this.name = "ImageLoadError";
  }
}

interface ISectionProofImage {
  images: any[];
  hasSeenAllImages: () => void;
}

const SectionProofImage: React.FC<ISectionProofImage> = ({
  images,
  hasSeenAllImages,
}: ISectionProofImage) => {
  let sliderRef = useRef<Slider>(null);

  const defaultSeenImages = new Array(images.length).fill(false);
  const [seenImages, setSeenImages] = useState<boolean[]>(defaultSeenImages);
  const [currentSlideNumber, setCurrentSlideNumber] = useState(0);

  const [ui] = useRecoilState(uiState);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const bgColor = ui.isLight ? ui.color1 : ui.color3;
  const gradientColor = ui.isLight ? ui.color2 : ui.color4;

  const disablePrevButton = currentSlideNumber === 0;
  const disableNextButton = currentSlideNumber === images.length - 1;

  const seenAllImages = seenImages.every((item) => item === true);

  useEffect(() => {
    if (seenAllImages) {
      hasSeenAllImages();
    }
  }, [seenAllImages]);

  const settings: Settings = {
    dots: true,
    infinite: false,
    slidesToShow: 1,
    speed: 500,
    centerMode: true,
    adaptiveHeight: true,
    slidesToScroll: 1,
    lazyLoad: "ondemand",
    arrows: false,
    dotsClass: "slick-dots slick-dots--custom",
    appendDots: (dots) => (
      <Box
        sx={{
          position: "relative !important",
          display: "grid !important",
          gridTemplateColumns: "1fr auto 1fr",
          flexGrow: 1,
          bottom: "auto !important",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "start" }}>
          <Button
            className="button"
            variant="contained"
            onClick={previous}
            disabled={disablePrevButton}
          >
            Previous
          </Button>
        </Box>
        <Box
          component="ul"
          sx={{
            display: "inline-flex",
          }}
        >
          {dots}
        </Box>
        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <Button
            className="button"
            variant="contained"
            onClick={next}
            disabled={disableNextButton}
          >
            Next
          </Button>
        </Box>
      </Box>
    ),
    customPaging: (i) => <Box>{i + 1}</Box>,
  };

  const isCheckeredStyles = ui.isCheckered
    ? {
        padding: "20px",
        backgroundColor: bgColor,
        backgroundImage: `
    linear-gradient(45deg, ${gradientColor} 25%, transparent 25%), 
    linear-gradient(-45deg, ${gradientColor} 25%, transparent 25%), 
    linear-gradient(45deg, transparent 75%, ${gradientColor} 75%), 
    linear-gradient(-45deg, transparent 75%, ${gradientColor} 75%)
  `,
        backgroundSize: `36px 36px`,
        backgroundPosition: `0 0, 0 18px, 18px -18px, -18px 0px`,
      }
    : {};

  const next = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };
  const previous = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  return (
    <Box>
      <div className="slider-container">
        <Slider
          {...settings}
          ref={sliderRef}
          beforeChange={(currentSlide: number, nextSlide: number) => {
            setCurrentSlideNumber(nextSlide);
          }}
        >
          {images.map((proof, index) => {
            const proofImage = proof.proofViewUrl;
            return (
              <Box
                key={index}
                sx={{
                  //   display: "flex",
                  //   justifyContent: "center",
                  //   alignItems: "center",
                  //   width: "100%",

                  ...isCheckeredStyles,
                }}
              >
                {loading && !error && (
                  <Skeleton variant="rectangular" width="100%" height="300px" />
                )}
                {error ? (
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      minHeight: "300px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      bgcolor: "grey.200",
                    }}
                  >
                    <BrokenImageIcon color="error" />
                    <Typography
                      variant="body2"
                      color="error"
                      sx={{ marginLeft: 1 }}
                    >
                      Image failed to load. Try reloading the page.
                    </Typography>
                  </Box>
                ) : (
                  <img
                    src={`${proofImage}`}
                    alt="Proof image"
                    onLoad={() => {
                      setLoading(false);

                      // Update the seen images values
                      if (seenAllImages === false) {
                        setSeenImages((prevArr) => {
                          return prevArr.map((item, idx) => {
                            if (idx === currentSlideNumber) {
                              return true;
                            }

                            return item;
                          });
                        });
                      }
                    }}
                    onError={() => {
                      setLoading(false);
                      setError(true);

                      Bugsnag.notify(
                        new Error("Proof carousel images failed to load")
                      );
                    }}
                    style={{
                      display: loading ? "none" : "block", // Hide image until it loads or errors
                    }}
                  />
                )}
              </Box>
            );
          })}
        </Slider>
      </div>
    </Box>
  );
};

export default React.memo(SectionProofImage);
