import { useUser } from "@/contexts/userContext";
import { id } from "@/utils/gid";
import { useEffect, useState } from "react";

const MaterialsComp = (props: { defId: string; value: any }) => {
  const user = useUser();
  const [materialName, setMaterialName] = useState("");

  useEffect(() => {
    const fetchMaterial = async () => {
      const resp = await fetch(`/api/v1/material/${id(props.value.id)}`, {
        headers: {
          Authorization: `Bearer ${user.state.token}`,
          "x-api-endpoint": user.state.apiEndpoint,
          "x-org-id": user.state.orgId,
        },
      });
      const data = await resp.json();

      setMaterialName(data.data.material.name);
    };

    fetchMaterial();
  }, []);

  return <div>{materialName}</div>;
};

const Metafield = (props: { defId: string; value: any }) => {
  switch (props.defId) {
    case "gid://glide/CoreMetafieldDefinitionType/MaterialRelationship": {
      return <MaterialsComp {...props} />;
    }
    case "gid://glide/CoreMetafieldDefinitionType/StickerSize":
    case "gid://glide/CoreMetafieldDefinitionType/LabelSize":
    case "gid://glide/CoreMetafieldDefinitionType/SheetSize":
    case "gid://glide/CoreMetafieldDefinitionType/MagnetSize":
      return (
        <div>
          {props.value.width} x {props.value.height}
          {props.value.unit} (WxH)
        </div>
      );
  }

  if (typeof props.value === "object") {
    return <div>{JSON.stringify(props.value)}</div>;
  }

  return <div>{props.value}</div>;
};

export default Metafield;
